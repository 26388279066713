// The BoxOffice component is the top-level component used to load a channel
// into view for an embed widget.  It manages the context for all sub-components
// in the tree and supports the flux models/stores.
//
// Usage:
//
//    <BoxOffice userArgs={ dictOfUserArgs }
//               model={ modelFactoryInstance } />
//

import React, { Suspense } from 'react';
import { CSSTransition } from 'react-transition-group';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';

import Messages from '../i18n';
import { Logger } from '../utils';
import { animateIntoView } from '../utils/dom';
import Config from '../config';
import BoxOfficeReloadMixin from '../mixins/box-office-reload-mixin';
import RefluxModelListenerMixin from '../mixins/reflux-model-listener-mixin';
import ResizeMixin from '../mixins/resize-mixin';

import TopThirdOverlay from '../components/player-overlays/top-third-overlay.jsx';
import PaymentModal from '../components/monetization/payment-modal.jsx';
import Playlist from '../components/playlist-area/playlist.jsx';
import DocumentsAndIndex from '../components/playlist-area/documents-and-index.jsx';
import TitleDescriptionWell from '../components/details-area/title-description-well.jsx';
import Highlights from '../components/library/highlights.jsx';
import HighlightEndedOverlay from '../components/player-overlays/highlight-ended-overlay.jsx';
import PasswordEntry from '../components/player-overlays/password-entry.jsx';
import Slot from '../components/slot.jsx';
import ErrorBoundary from '../components/library/error-boundary';

const ChatLazyLoad = React.lazy(() => import('../components/chat/chat.tsx'));

import Player from '../player/player.jsx';

const logger = Logger.getInstance('components');

const BoxOffice = createClass({
  displayName: 'BoxOffice',

  ///
  /// Tee up the React lifecycle for this component
  ///

  propTypes: {
    model: PropTypes.object,
    userArgs: PropTypes.object,
  },

  refluxModelListeners: {
    PlaylistStore: 'onStoreChanged',
    CurrentChannelStore: 'onStoreChanged',
    SelectedBroadcastStore: 'onStoreChanged',
    TicketStore: 'onStoreChanged',
    UserArgsStore: 'onStoreChanged',
    GeoBlockStore: 'onStoreChanged',
    PasswordBlockStore: 'onStoreChanged',
    Actions: [
      { on: 'SelectBroadcast', trigger: 'onSelectBroadcast' },
      { on: 'UpdateSlot', trigger: 'onStoreChanged' },
    ],
  },

  mixins: [BoxOfficeReloadMixin, RefluxModelListenerMixin, ResizeMixin],

  childContextTypes: {
    model: PropTypes.object,
    userArgs: PropTypes.object,
    m: PropTypes.func,
    env: PropTypes.func,
  },

  getChildContext: function () {
    // We extract some special metadata that can flow freely throughout the
    // component tree without explicitly passing as props/state as a shortcut.
    // This utilizes the React `context` paradigm.
    return {
      model: this.props.model,
      userArgs: this.props.userArgs,
      m: this.m,
      env: this.env,
    };
  },

  m: function (...args) {
    // Shortcut method to allow user-supplied messages to override defaults
    return Messages.factory(this.props.userArgs.messages)(...args);
  },

  env: function (key) {
    // Shortcut method to allow user-supplied environment to override defaults
    return Config.factory(this.props.userArgs.env)(key);
  },

  getDefaultProps: function () {
    return {
      model: {},
      userArgs: {},
    };
  },

  getInitialState: function () {
    return { ...this.getStateFromRefluxStores(), chatVisible: false };
  },

  getStateFromRefluxStores: function () {
    const {
      SelectedBroadcastStore,
      CurrentChannelStore,
      TicketStore,
      PlaylistStore,
      UserArgsStore,
      GeoBlockStore,
      PasswordBlockStore,
      SlotStore,
    } = this.props.model;

    const cs = (SelectedBroadcastStore.broadcast || {}).content_settings || {};
    let state = {
      broadcast: SelectedBroadcastStore.broadcast,
      view: SelectedBroadcastStore.view,
      highlights: SelectedBroadcastStore.highlights,
      selectedHighlight: SelectedBroadcastStore.selectedHighlight,
      isLoading: !!(
        SelectedBroadcastStore.loading ||
        CurrentChannelStore.loading ||
        TicketStore.loading ||
        PlaylistStore.loading
      ),
      blockEverything: TicketStore.loading,
      paymentModalOpen: TicketStore.modalOpen,
      isPreviewExpired: SelectedBroadcastStore.timeLimitedPreviewExpired,
      freeVariant: SelectedBroadcastStore.freeVariant,
      isPreview: SelectedBroadcastStore.timeLimitedPreview,
      ended: SelectedBroadcastStore.ended,
      channelId: CurrentChannelStore.id,
      poster: UserArgsStore.preview || UserArgsStore.poster,
      customPreroll: UserArgsStore.customPreroll,
      hidePreBroadcastTextOverlay: UserArgsStore.hidePreBroadcastTextOverlay,
      isGeoBlockLoading: GeoBlockStore.isLoading,
      isGeoBlockResticted: GeoBlockStore.isRestricted,
      isPasswordBlockRestricted: PasswordBlockStore.isRestricted,
      isBlocked: SelectedBroadcastStore.isBlocked(),
      showChat: this.shouldShowChat(),
      slots: SlotStore.slots,
    };

    return state;
  },

  // Chat preferences are an amalgamation of embed code preferences + content
  // settings overrides.  This allows chat to be toggled on/off remotely.
  shouldShowChat: function () {
    const { SelectedBroadcastStore } = this.props.model;
    const { view } = SelectedBroadcastStore;

    // If the view hasn't loaded yet (e.g. we're in the process of switching to another
    // broadcast in the playlist), defer to the previous value of showChat
    if (!view.settings) {
      return this.state && this.state.showChat;
    }

    // If the view response doesn't have a `chat_db` configured, then
    // the account isn't even eligible to use chat, so don't even attempt it.
    if (!view.settings.chat_db) {
      return false;
    }

    // If they haven't enabled it in the embed code setting, we won't ever
    // attempt to show chat.  Note that this is _always_ true on boxcast.tv
    if (!this.props.userArgs.showChat) {
      return false;
    }

    // The user _also_ must enable chat by way of account/broadcast content settings,
    // (either the global and/or per-broadcast toggle on the dashboard)
    if (!view.settings.showChat) {
      return false;
    }

    return true;
  },

  onStoreChanged: function () {
    this.setState(this.getStateFromRefluxStores());

    // New video was possibly selected; need to reset the reloader mixin
    this.initBoxOfficeReloadMixin();
  },

  onSelectBroadcast: function (broadcast, args) {
    // New video was selected; need to reset the reloader mixin
    this.initBoxOfficeReloadMixin();

    if (args && args.fromPlaylist) {
      var playerElement = ReactDOM.findDOMNode(this.refs.playerElement);
      animateIntoView(playerElement, 500);
    }
  },

  ///
  /// Render the component
  ///

  renderPlayer: function (playerBg) {
    const {
      broadcast,
      view,
      selectedHighlight,
      isPreviewExpired,
      isGeoBlockLoading,
      isGeoBlockResticted,
      isPasswordBlockRestricted,
      freeVariant,
      poster,
      customPreroll,
      hidePreBroadcastTextOverlay,
      isBlocked,
    } = this.state;
    const { userArgs } = this.props;

    var show, borh, bview, autoplay, loop;

    if (isGeoBlockLoading || isGeoBlockResticted || isPasswordBlockRestricted) {
      show = false;
    } else if (isPreviewExpired && !freeVariant) {
      show = false;
    } else if (view && view.playlist) {
      show = true;
      borh = selectedHighlight ? selectedHighlight : broadcast;
      bview = view;
      autoplay = userArgs.autoplay;
    } else if (Object.keys(view).length > 0 && customPreroll) {
      show = true;
      borh = selectedHighlight ? selectedHighlight : broadcast;
      bview = { ...view, playlist: customPreroll };
      autoplay = true;
      loop = true;
    } else {
      show = false;
    }

    if (userArgs.autoplay === false && userArgs.admin) {
      autoplay = false;
    }

    var hideTopThirdOverlay = isPasswordBlockRestricted;
    if (
      hidePreBroadcastTextOverlay &&
      (broadcast.timeframe == 'future' || broadcast.timeframe == 'preroll')
    ) {
      hideTopThirdOverlay = true;
    }

    if (isBlocked) {
      return (
        <div className="boxcast-player-wrapper">
          <div className="boxcast-player-inner">
            <h3>This account is not eligible to broadcast.</h3>
          </div>
        </div>
      );
    } else if (broadcast.do_not_record && this.state.ended) {
      show = false;
    }

    return (
      <div className="boxcast-player-wrapper">
        <div className="boxcast-player-inner" style={playerBg}>
          {show ? (
            <Player
              broadcast={borh}
              broadcastView={bview}
              autoplay={autoplay}
              customPoster={poster}
              loop={loop}
            />
          ) : null}
          {isPasswordBlockRestricted ? <PasswordEntry /> : null}
        </div>
        {hideTopThirdOverlay ? null : <TopThirdOverlay />}
        {this.renderLoading()}
        {this.renderEnded()}
      </div>
    );
  },

  renderLoading: function () {
    // TODO: consider differentiating between different types of loading
    const { isLoading } = this.state;

    return (
      <CSSTransition in={false} timeout={300} classNames="boxcast-loader-react-transition">
        {isLoading ? (
          <div key="box-office-loading" className="boxcast-loader-overlay">
            <div className="boxcast-loader">Loading...</div>
          </div>
        ) : (
          <span key="box-office-not-loading" />
        )}
      </CSSTransition>
    );
  },

  renderEnded: function () {
    if (this.state.ended && this.state.selectedHighlight) {
      return (
        <HighlightEndedOverlay
          showSocialShare={this.props.userArgs.showSocialShare}
          broadcast={this.state.broadcast}
          channelId={this.state.channelId}
        />
      );
    }
  },

  renderPaymentModal: function () {
    const { isPreview, paymentModalOpen } = this.state;

    if (isPreview || paymentModalOpen) {
      return <PaymentModal />;
    }
  },

  renderBlockEverything: function () {
    const { blockEverything } = this.state;
    if (blockEverything) {
      return <div className="boxcast-block-everything"></div>;
    }
  },

  renderChat: function () {
    if (this.state.showChat) {
      return (
        <Suspense fallback={<div></div>}>
          <ErrorBoundary>
            <ChatLazyLoad
              broadcast={this.state.broadcast}
              view={this.state.view}
              fixedMobileLayout={this.props.userArgs.fixedMobileLayout}
              onShowChatToggle={(visible) => {
                this.setState({ chatVisible: visible });
              }}
            />
          </ErrorBoundary>
        </Suspense>
      );
    }
  },

  render: function () {
    const { userArgs } = this.props;
    const { componentWidthDesc, broadcast, poster } = this.state;
    const playerBg = {};
    
    const pre_thumbnail = broadcast.preview || poster;
    const post_thumbnail = ()=>{
      if(broadcast.content_settings && (broadcast.content_settings || {}).post_thumbnail ){
        const type = broadcast.content_settings.post_thumbnail.type
        if (!broadcast.content_settings.post_thumbnail.url && (type === 'frame' || type === 'pre')){
          // If post_thumnail object exists, BUT there is no post_thumnail URL and either type === frame or === pre,then use broadcast's poster. 
          return broadcast.poster
        } else { 
          // Otherwise, use the post_thumnail.url (even if it is an empty string)
         return broadcast.content_settings.post_thumbnail.url
        }  
      }else{
        // If post_thumbnail does not exist, use poster or preview
        return  !!broadcast.poster ? broadcast.poster : pre_thumbnail;
      }
    }

    const url = broadcast.timeframe === 'past' ? post_thumbnail() : pre_thumbnail

    if(url && broadcast.content_settings){
      playerBg.backgroundImage = `url("${url}")`;
      playerBg.backgroundSize = 'cover';
      playerBg.backgroundRepeat = 'no-repeat';
      playerBg.backgroundPosition = '50% 50%';
    }

    const aspectRatioClass = (userArgs.aspectRatio || '16:9').replace(':', '_');

    const classNames = [
      `boxcast-boxoffice`,
      `boxcast-theme-${userArgs.theme || 'light'}`,
      `boxcast-ar-${aspectRatioClass}`,
      `boxcast-size-${componentWidthDesc}`,
      this.state.chatVisible ? 'boxcast-chat-visible' : '',
      userArgs.staticMode ? 'static-mode' : ''
    ].join(' ');

    if (userArgs.layout == 'playlist-to-right') {
      return this.renderWithPlaylistToRight(classNames, playerBg, poster);
    } else {
      return this.renderWithTitlePlaylistSideBySide(classNames, playerBg, poster);
    }
  },

  renderWithTitlePlaylistSideBySide(classNames, playerBg, poster) {
    const { userArgs } = this.props;
    const { SlotStore } = this.props.model;
    return (
      <div className={classNames}>
        <div className="boxcast-player-container" ref="playerElement">
          {this.renderPlayer(playerBg)}
        </div>
        <div className="boxcast-well-container">
          <TitleDescriptionWell showHighlights={userArgs.showHighlights} />
          <Slot name={SlotStore.SLOTS.AFTER_TITLE_CARD} />
          {this.renderChat()}
          {userArgs.showIndex || userArgs.showDocuments ? <DocumentsAndIndex /> : <span />}
          {userArgs.showRelated ? <Playlist defaultPoster={poster}  /> : <span />}
          <Slot name={SlotStore.SLOTS.AFTER_PLAYLIST} />
        </div>
        {this.renderPaymentModal()}
        {this.renderBlockEverything()}
      </div>
    );
  },

  renderWithPlaylistToRight(classNames, playerBg, poster) {
    const { userArgs } = this.props;
    const { SlotStore } = this.props.model;
    return (
      <div className={classNames}>
        <div className="boxcast-with-playlist-to-right">
          <div className="boxcast-well-container boxcast-with-playlist-to-right-col-1">
            <div className="boxcast-player-container" ref="playerElement">
              {this.renderPlayer(playerBg)}
            </div>
            <TitleDescriptionWell showHighlights={false} />
            <Slot name={SlotStore.SLOTS.AFTER_TITLE_CARD} />
          </div>
          <div className="boxcast-well-container boxcast-with-playlist-to-right-col-2">
            {this.renderChat()}
            {this.state.highlights.length ? (
              <div className="boxcast-well">
                <div className="boxcast-well-title">
                  <h3>Highlights</h3>
                </div>
                <Highlights title={null} highlights={this.state.highlights} />
              </div>
            ) : (
              <span />
            )}
            {userArgs.showIndex || userArgs.showDocuments ? <DocumentsAndIndex /> : <span />}
            {userArgs.showRelated ? <Playlist defaultPoster={poster} /> : <span />}
            <Slot name={SlotStore.SLOTS.AFTER_PLAYLIST} />
          </div>
          {this.renderPaymentModal()}
          {this.renderBlockEverything()}
        </div>
      </div>
    );
  },
});

export default BoxOffice;
